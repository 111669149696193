import axios from "axios";
import { IData, Resolve } from ".";

export const ticketQrcodeCheck = (ticketCode: string) =>
  new Promise((resolve: Resolve<IData>, reject) => {
    const requestUrl = `https://api.beamian.com/api/v2/ticket-qrcode-check/${ticketCode}`;

    axios
      .get(requestUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
