<template>
  <router-view />
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/rubik/rubik-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/rubik/rubik-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
body {
  background-color: #e8e8e8;
}
#app {
  font-family: "Rubik", "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3e5460;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
